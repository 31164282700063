/**
 * @description The permissions are handled on the application level. So
 * There is no collection for permissions in the database. The permissions
 * are assigned to roles and the roles are assigned to users. Roles are
 * stored in the database and linked to the users.
 */
export type Permission =
  | "manageAdmins"
  | "manageUsers"
  | "manageRoles"
  | "manageGroups"
  | "billing"
  | "submitFiling"
  | "manageFilers";

export const allPermissions: Permission[] = [
  "manageUsers",
  "manageRoles",
  "manageGroups",
  "billing",
  "submitFiling",
  "manageFilers",
];

export type Role = "SuperAdmin" | "Admin" | "Filer" | "Preparer";

export const rolesToPermissions: Record<Role, Permission[]> = {
  SuperAdmin: [...allPermissions, "manageAdmins"],
  Admin: allPermissions,
  Filer: ["submitFiling", "manageFilers"],
  Preparer: ["manageFilers"],
};
