import { IUser } from "src/app/core/interfaces";

export interface UserStateModel extends IUser {
  id: string;
}

export const generateDefaultUserState = (): UserStateModel => ({
  id: "",
  name: "",
  consentFirstName: "",
  consentLastName: "",
  email: "",
  orgId: "",
  skipTwoFactor: false,
  permissions: [],
  roles: ["Admin"],
  userType: null,
  stripeCustomerId: null,
  quickStartState: null,
  proAssociation: null,
});
