<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial>
    {{ data.negativeActionButtonText }}
  </button>
  <button
    mat-button
    [mat-dialog-close]="true"
    [color]="data.positiveAction ? 'primary' : 'warn'"
  >
    {{ data.positiveActionButtonText }}
  </button>
</mat-dialog-actions>
