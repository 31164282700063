import { Component, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";

@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"],
})
export class BaseComponent implements OnDestroy {
  protected isDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  /**
   * This is a generic loading indicator that can be used in any component.
   * You can use it without any parameters if you only need one loading indicator.
   * If you need more than one loading indicator, you can pass a key to all the
   * methods to differentiate between different loading states.
   */
  private isLoadingSourceMap: Map<string, BehaviorSubject<boolean>> = new Map();
  private isLoadingMap: Map<string, Observable<boolean>> = new Map();
  private anythingLoadingSource$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public anythingLoading$ = this.anythingLoadingSource$.asObservable();
  private defaultKey =
    "defaultKeyCollisionStopper420_YeYe_If_You_Manage_To_Collide_With_This_Key_You_Deserve_It";

  public firstLoadAchieved = false;

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  protected get isLoading$(): Observable<boolean> {
    return this.isLoadingItem$(this.defaultKey);
  }

  /** Used to fetch an keyed isLoading observable */
  protected isLoadingItem$(key: string) {
    this.initIsLoadingItem(key);
    return this.isLoadingMap.get(key) as Observable<boolean>;
  }

  /** Convenience method for logging stuff out from templates */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public log(value: any) {
    console.log(value);
  }

  /** Create a record in the loading subjects map */
  private initIsLoadingItem(key: string) {
    if (!this.isLoadingSourceMap.has(key)) {
      this.isLoadingSourceMap.set(key, new BehaviorSubject(false));
      this.isLoadingMap.set(
        key,
        (
          this.isLoadingSourceMap.get(key) as BehaviorSubject<boolean>
        ).asObservable()
      );
    }
  }

  protected startLoading(key?: string) {
    if (!key) {
      key = this.defaultKey;
    }
    this.initIsLoadingItem(key);
    (this.isLoadingSourceMap.get(key) as BehaviorSubject<boolean>).next(true);
    this.anythingLoadingSource$.next(true);
  }

  protected stopLoading(key?: string) {
    if (!key) {
      key = this.defaultKey;
    }
    this.initIsLoadingItem(key);
    (this.isLoadingSourceMap.get(key) as BehaviorSubject<boolean>).next(false);
    this.anythingLoadingSource$.next(
      Array.from(this.isLoadingSourceMap.values()).some((v) => v.value)
    );
  }
}
