import { Injectable } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RouteTitleService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {}

  initializeTitleService(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        route.data.subscribe((data) => {
          this.titleService.setTitle(data["title"] || "Secure Compliance");
        });
      });
  }
}
