<!-- Minimized Quick Start -->
<ng-container>
  <div
    class="minimized-container"
    (click)="openQuickStart()"
    (keypress)="openQuickStart()"
    tabindex="0"
  >
    <div
      echarts
      [options]="chartOption"
      (chartInit)="onChartInit($event)"
      class="mini-qs-pie"
    ></div>
    <div class="mini-info">
      <div class="mat-subtitle-2">Quick Start</div>
      <div class="hint-text">{{ numberOfTasksRemaining }} Remaining Tasks</div>
    </div>
  </div>
</ng-container>
