export namespace App {
  export class Reset {
    static readonly type = "[App] Reset";
  }

  export class CloseSidebar {
    static readonly type = "[App] Close Sidebar";
  }

  export class OpenSidebar {
    static readonly type = "[App] Open Sidebar";
  }

  export class SetWaitingToUpdate {
    static readonly type = "[App] Set Waiting To Update";
    constructor(public waitingToUpdate: boolean) {}
  }
}
