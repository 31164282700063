import { Injectable } from "@angular/core";
import { IEntity, IEntityTotalProgress } from "../interfaces";
import { Functions } from "@angular/fire/functions";
import { MatSnackBar } from "@angular/material/snack-bar";
import { httpsCallable } from "firebase/functions";

@Injectable({
  providedIn: "root",
})
export class EntityService {
  constructor(private functions: Functions, private snackbar: MatSnackBar) {}

  async getTotalEntityProgress(entity: IEntity) {
    const getTotalEntityProgressCallable = httpsCallable<
      { entity: IEntity },
      {
        success: boolean;
        message: string;
        data: IEntityTotalProgress;
      }
    >(this.functions, "getTotalEntityProgress");

    if (!entity) {
      throw new Error("Entity not provided.");
    }

    const response = await getTotalEntityProgressCallable({ entity });

    if (!response.data.success) {
      this.snackbar.open(response.data.message, "Dismiss", {
        duration: 3000,
      });
      return;
    }

    return response.data.data;
  }
}
