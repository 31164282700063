import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { v4 as uuidV4 } from "uuid";
import { IAddressForm, IOwnerForm } from "../interfaces";
import { usTerritories } from "./constants";
import { FormValidationService } from "../services/form-validation.service";

function conditionalOwnerValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const group = control as FormGroup<IOwnerForm>;
    const fincenId = group.controls.fincenId;
    const hasFincenId = group.controls.hasFincenId;
    const isEntity = group.controls.isEntity;
    const isCompanyApplicant = group.controls.isCompanyApplicant;

    // Check if hasFincenId is true and set the fincenId to required
    if (hasFincenId.value === true || isEntity.value === true) {
      fincenId.addValidators(Validators.required);
      fincenId.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    } else {
      fincenId.removeValidators(Validators.required);
      fincenId.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }

    // Check if isEntity is true and set the entityLegalName to required
    if (isEntity.value === true) {
      group.controls.entityLegalName.addValidators(Validators.required);
      group.controls.entityLegalName.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    } else {
      group.controls.entityLegalName.removeValidators(Validators.required);
      group.controls.entityLegalName.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }

    // Check if fincenId is filled out
    if (hasFincenId.value && fincenId.valid) {
      // If the fincenId is valid, then we can clear all validators on the form
      // except for the first and last name
      Object.keys(group.controls).forEach((key) => {
        if (key === "fincenId" || key === "firstName" || key === "lastName")
          return;

        // If the owner is an entity, then we don't clear entityLegalName validator
        if (isEntity.value && key === "entityLegalName") return;

        // If the owner is not a company applicant, then we don't clear email validator
        if (key === "email" && !isCompanyApplicant.value) {
          return;
        }

        const control = group.get(key);

        if (control instanceof FormGroup) {
          Object.keys(control.controls).forEach((key) => {
            control.get(key)?.removeValidators(Validators.required);
            control
              .get(key)
              ?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
          });
        }
        control?.removeValidators(Validators.required);
        control?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      });
    } else {
      // If the fincenId is not valid, then we need to reapply the validators
      // to the form
      const fieldsThatShouldBeRequired = [
        "email",
        "dob",
        "docIdNum",
        "docType",
        "address",
        "streetLine1",
        "city",
        "state",
        "zip",
        "country",
      ];
      // If the owner is a company applicant, then we need to add addressType
      if (isCompanyApplicant.value) {
        fieldsThatShouldBeRequired.push("addressType");
      }
      Object.keys(group.controls).forEach((key) => {
        if (fieldsThatShouldBeRequired.includes(key)) {
          const control = group.get(key);

          if (control instanceof FormGroup) {
            Object.keys(control.controls).forEach((key) => {
              if (fieldsThatShouldBeRequired.includes(key)) {
                control.get(key)?.addValidators(Validators.required);
                control.get(key)?.updateValueAndValidity({
                  onlySelf: true,
                  emitEvent: false,
                });
              }
            });
          }
          group.get(key)?.addValidators(Validators.required);
          group
            .get(key)
            ?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
      });
    }

    return null; // Return null if no errors are found
  };
}

export const getOwnerType = (
  isEntity: boolean | null,
  isCompanyApplicant: boolean | null
) => {
  if (isEntity) {
    return "Entity Owner";
  }
  if (isCompanyApplicant) {
    return "Company Applicant";
  } else {
    return "Beneficial Owner";
  }
};

export const generateBlankOwnerForm = () => {
  const ownerForm = new FormGroup<IOwnerForm>(
    {
      id: new FormControl(uuidV4()),
      firstName: new FormControl("", [Validators.required]),
      middleName: new FormControl(""),
      lastName: new FormControl("", [Validators.required]),
      suffix: new FormControl(null),
      email: new FormControl("", [
        FormValidationService.emailValidator(),
        Validators.required,
      ]),
      docIdNum: new FormControl("", [Validators.required]),
      docType: new FormControl(null, [Validators.required]),
      dob: new FormControl(null, [Validators.required]),
      jurisdictionIssuer: new FormControl("United States", [
        Validators.required,
      ]),
      issuerState: new FormControl(""),
      issuerTribe: new FormControl(""),
      issuerOtherTribe: new FormControl(""),
      docUrl: new FormControl(""),
      fincenId: new FormControl(""),
      hasFincenId: new FormControl(false),
      countOfIdCollectionEmailsSent: new FormControl(0, { nonNullable: true }),
      lastIdCollectionEmailSent: new FormControl(null),
      isCompanyApplicant: new FormControl(false, {
        nonNullable: true,
      }),
      autoCollectId: new FormControl(false, { nonNullable: true }),
      minor: new FormControl(null),
      isEntity: new FormControl(null),
      isExemptEntity: new FormControl(null),
      isSignatoryEntity: new FormControl(null),
      entityLegalName: new FormControl(""),
      addressType: new FormControl(""),
      address: new FormGroup<IAddressForm>(
        {
          streetLine1: new FormControl("", [Validators.required]),
          streetLine2: new FormControl(""),
          city: new FormControl("", [Validators.required]),
          state: new FormControl("", [Validators.required]),
          zip: new FormControl("", [Validators.required]),
          country: new FormControl("United States"),
        },
        { validators: [Validators.required] }
      ),
      internalNotes: new FormControl(null),
    },
    { validators: [conditionalOwnerValidator()] }
  );
  return ownerForm;
};

export const zipCodeRequired = (jurisdiction: string | null): boolean => {
  if (!jurisdiction) return false;
  return (
    jurisdiction === "United States" ||
    jurisdiction === "Canada" ||
    jurisdiction === "Mexico" ||
    usTerritories.includes(jurisdiction)
  );
};
