type AsyncCallable = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...args: any[]): Promise<any>;
}

interface RepeaterSuccessResponse<T> {
  success: true;
  data: T;
  error?: undefined;
}

interface RepeaterFailureResponse {
  success: false;
  data?: undefined;
  error: string;
}

type RepeaterResponse<T> = RepeaterSuccessResponse<T> | RepeaterFailureResponse;

type PromiseResolved<TFunction extends AsyncCallable, TFunctionReturnType = ReturnType<TFunction>> =
  TFunctionReturnType extends Promise<infer TReturnType> ? TReturnType : never;

type RepeatAsyncResponse<TFunction extends AsyncCallable> = Promise<RepeaterResponse<PromiseResolved<TFunction>>>;

export class FunctionRepeater {
  constructor(
    private _functionName = 'Repeating Function',
    private _repeatCount = 3,
    private _timeout = 0
  ) {}

  async repeatAsync<TFunction extends AsyncCallable>(func: TFunction, ...args: Parameters<TFunction>): RepeatAsyncResponse<TFunction> {
    const repeatArray = Array.from(Array(this._repeatCount).keys());

    for await (const repeatTry of repeatArray) {
      const currentTry = repeatTry + 1;
      console.log(`Repeating: ${this._functionName}, Try: ${currentTry}`);

      if (repeatTry !== 0 && this._timeout !== 0) {
        await this.waitBeforeRetry();
      }

      try {
        const response = await func(...args);

        return {
          success: true,
          data: response,
        };
      } catch (error) {
        if (currentTry === this._repeatCount) {
          if (error instanceof Error) {
            return {
              success: false,
              error: error.message,
            }
          } else {
            return {
              success: false,
              error: 'Function repeat failed',
            }
          }
        }
      }
    }

    return {
      success: false,
      error: `Request did not succeed after ${this._repeatCount + 1} times`,
    };
  }

  private async waitBeforeRetry() {
    console.log(`Waiting before making next retry`);

    return new Promise(res => setTimeout(res, this._timeout));
  }
}
