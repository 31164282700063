import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface ActionDialogData {
  title: string;
  message: string;
  positiveAction: boolean;
  positiveActionButtonText: string;
  negativeActionButtonText: string;
}

@Component({
  selector: "app-action-dialog",
  templateUrl: "./action-dialog.component.html",
  styleUrls: ["./action-dialog.component.scss"],
})
export class ActionDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ActionDialogData
  ) {}
}
