import { Component, EventEmitter, OnInit, Output, inject } from "@angular/core";
import { Store } from "@ngxs/store";
import { EChartsOption, ECharts, PieSeriesOption } from "echarts";
import { UserStateModel } from "src/app/state/user-state/user-model.interface";
import { BaseComponent } from "../../../base/base.component";
import { takeUntil, tap } from "rxjs";
import {
  calculatePercentComplete,
  defaultQuickStartState,
} from "../../../../utils/quick-start-helpers";
import { User } from "src/app/state/user-state/user.actions";

@Component({
  selector: "app-quick-start",
  templateUrl: "./quick-start.component.html",
  styleUrls: ["./quick-start.component.scss"],
})
export class QuickStartComponent extends BaseComponent implements OnInit {
  @Output() quickStartClicked = new EventEmitter<void>();

  private store = inject(Store);

  user$ = this.store.select<UserStateModel>((state) => state.user);

  minimized = false;
  numberOfTasksRemaining = 0;

  echartsInstance: ECharts | undefined;
  chartOption: EChartsOption = {
    series: [
      {
        type: "pie",
        radius: "100%",
        label: {
          show: false,
        },
        data: [
          { value: 0, name: "Completed", itemStyle: { color: "#00567a" } },
          {
            value: 100,
            name: "Remaining",
            itemStyle: { color: "#D3D3D3" },
          },
        ],
        emphasis: {
          disabled: true,
        },
      },
    ],
  };

  ngOnInit(): void {
    this.user$
      .pipe(
        takeUntil(this.isDestroyed$),
        tap((user) => {
          if (user) {
            const qsStatus = calculatePercentComplete(user);
            if (!qsStatus) {
              this.store.dispatch(
                new User.SetQuickStartState(defaultQuickStartState())
              );
              return;
            }
            this.setChartOption(qsStatus.percentComplete);
            this.numberOfTasksRemaining = qsStatus.tasksRemaining;
          }
        })
      )
      .subscribe();
  }

  /**
   * When the echart initializes in the template, this method is called.
   * It will set the echartsInstance property to the instance of the chart and
   * then get the percent complete from the user state and update the chart.
   */
  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
    const userState = this.store.selectSnapshot<UserStateModel>(
      (state) => state.user
    );
    const qsStatus = calculatePercentComplete(userState);
    if (!qsStatus) {
      this.store.dispatch(
        new User.SetQuickStartState(defaultQuickStartState())
      );
      return;
    }
    this.setChartOption(qsStatus.percentComplete);
    this.numberOfTasksRemaining = qsStatus.tasksRemaining;
  }

  private setChartOption(percentComplete: number) {
    if (Array.isArray(this.chartOption.series)) {
      const chartData = this.chartOption.series[0].data as PieSeriesOption[];
      this.chartOption.series[0].data = chartData.map((d, i) => {
        if (i === 0) {
          return { ...d, value: percentComplete };
        } else {
          return { ...d, value: 100 - percentComplete };
        }
      });
    }
    this.echartsInstance?.setOption(this.chartOption);
  }

  openQuickStart() {
    this.quickStartClicked.emit();
  }
}
