import { IUserQuickStartState } from "src/app/core/interfaces";
import { UserStateModel } from "./user-model.interface";

export namespace User {
  export class Get {
    static readonly type = "[User] Get";
    constructor(public uid: string) {}
  }

  export class Reset {
    static readonly type = "[User] Reset";
  }

  export class SetQuickStartState {
    static readonly type = "[User] Set Quick Start State";
    constructor(public quickStartState: IUserQuickStartState) {}
  }

  export class UpdateConsentData {
    static readonly type = "[User] Update consent data";
    constructor(public consentFirstName: string, public consentLastName: string) {}
  }

  export class Update {
    static readonly type = "[User] Update";
    constructor(public updatedUser: UserStateModel) {}
  }
}
