import { Action, State, StateContext } from "@ngxs/store";
import { AppStateModel, defaultAppState } from "./app-model.interface";
import { Injectable } from "@angular/core";
import { App } from "./app.actions";
import { Organization } from "../secure-pro-state/secure-pro.actions";
import { SecureFile } from "../secure-file-state/secure-file.actions";


@State<AppStateModel>({
  name: "app",
  defaults: { ...defaultAppState },
})
@Injectable()
export class AppState {
  @Action(App.Reset)
  reset(ctx: StateContext<AppStateModel>) {
    ctx.setState({ ...defaultAppState });
    // TODO: Reset all other states
    ctx.dispatch(new Organization.Reset());
    ctx.dispatch(new SecureFile.Reset());
  }

  @Action(App.CloseSidebar)
  closeSidebar(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ sidebarOpen: false });
  }

  @Action(App.OpenSidebar)
  openSidebar(ctx: StateContext<AppStateModel>) {
    ctx.patchState({ sidebarOpen: true });
  }

  @Action(App.SetWaitingToUpdate)
  setWaitingToUpdate(
    ctx: StateContext<AppStateModel>,
    action: App.SetWaitingToUpdate
  ) {
    ctx.patchState({ waitingToUpdate: action.waitingToUpdate });
  }
}
