import { Injectable, inject } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  IHttpsCallableResponse,
  IUploadImageRequest,
  IManualIdUploadDialogDataOutput,
} from "../interfaces";
import { Functions } from "@angular/fire/functions";
import { httpsCallable } from "@firebase/functions";
import imageCompression from "browser-image-compression";

@Injectable({
  providedIn: "root",
})
export class CommonApisService {
  snackbar = inject(MatSnackBar);
  functions = inject(Functions);

  async uploadGovIdImage(requestData: IUploadImageRequest) {
    if (
      !["jpeg", "jpg", "png", "pdf"].some((mediaType) =>
        requestData.mediaType.includes(mediaType)
      )
    ) {
      throw new Error("Invalid media type");
    }
    const uploadGovIdImageCallable = httpsCallable<
      IUploadImageRequest,
      IHttpsCallableResponse
    >(this.functions, "uploadGovIdImage");

    const uploadGovIdImageResponse = await uploadGovIdImageCallable(
      requestData
    );
    if (!uploadGovIdImageResponse.data.success) {
      this.snackbar.open(uploadGovIdImageResponse.data.message, "Close", {
        duration: 5000,
      });
      throw new Error(uploadGovIdImageResponse.data.message);
    } else {
      return uploadGovIdImageResponse.data;
    }
  }
  private async base64ToFile(base64String: string, mimeType: string) {
    // Decode the Base64 string to a binary array
    const bytes = atob(base64String);
    const byteArray = new Uint8Array(bytes.length);

    for (let i = 0; i < bytes.length; i++) {
      byteArray[i] = bytes.charCodeAt(i);
    }

    // Create a Blob and File object
    const blob = new Blob([byteArray], { type: mimeType });
    return new File([blob], "uploaded-image", { type: mimeType });
  }

  private async fileToBase64(file: File): Promise<string> {
    const base64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

    // We don't need the mime type so we'll only get the
    // base64 string
    const parts = (base64 as string).split(",");
    return parts[1];
  }

  async validateOrCompressGovIdImage(
    requestData: IManualIdUploadDialogDataOutput
  ) {
    // Assuming the image is a base64 string in this.image
    const file = await this.base64ToFile(
      requestData.image,
      requestData.mediaType
    );
    const imageInBytes = (requestData.image.length * 6) / 8;
    const imageInMB = imageInBytes / (1024 * 1024);
    // If the file type is pdf, we need to make sure that it's not bigger
    // than 4MB
    if (requestData.mediaType.includes("pdf")) {
      if (imageInMB > 4) {
        this.snackbar.open("Image size exceeds 4MB limit.", "Dismiss", {
          duration: 3000,
        });
        return false;
      }
    }

    // If the file type is an image, we need to compress it
    if (!requestData.mediaType.includes("pdf")) {
      // If the image size is under 4MB, no need to compress
      const options = {
        maxSizeMB: 4,
        maxWidthOrHeight: 900,
      };

      const compressedImage = await imageCompression(file, options);
      // Convert the compressed image to a base64 string
      const base64Image = await this.fileToBase64(compressedImage);
      requestData.image = base64Image;
    }
    return requestData;
  }

  async variableImageCompressor(
    image: string,
    contentType: string,
    maxSizeMB: number,
    maxWidthOrHeight: number
  ): Promise<IManualIdUploadDialogDataOutput> {
    const file = await this.base64ToFile(image, contentType);

    if (contentType.includes("pdf")) {
      return {
        image: image,
        mediaType: "application/pdf",
      };
    }

    if (!contentType.includes("pdf")) {
      const options = {
        maxSizeMB,
        maxWidthOrHeight,
      };

      const compressedImage = await imageCompression(file, options);
      const base64File = await this.fileToBase64(compressedImage);

      console.log("compressed image size: ", base64File.length);

      return {
        image: base64File,
        mediaType: contentType,
      };
    }
    return {
      image,
      mediaType: contentType,
    };
  }
}
