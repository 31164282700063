import { inject } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { CanActivateFn, NavigationExtras, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { first, map } from "rxjs";
import { UserStateModel } from "src/app/state/user-state/user-model.interface";
import { Permission } from "../interfaces";

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const auth = inject(Auth);
  const store = inject(Store);
  const userState$ = store.select<UserStateModel>((state) => state.user);

  const navigationExtras: NavigationExtras = {
    queryParams: { redirectUrl: state.url },
    fragment: "anchor",
  };

  if (
    route.routeConfig?.path === "two-factor-setup" &&
    auth.currentUser?.providerData.find(
      (provider) => provider.providerId !== "password"
    )
  ) {
    return router.createUrlTree(["unauthorized"], navigationExtras);
  }

  return userState$.pipe(
    map((userState) => {
      if (!!userState?.id && !!auth?.currentUser) {
        const requiredPermissions =
          (route.data?.["permissions"] as Permission[]) || [];
        if (userHasRequiredPermissions(userState, requiredPermissions)) {
          return true;
        } else {
          return router.createUrlTree(["unauthorized"], navigationExtras);
        }
      } else {
        return router.createUrlTree(["auth/login"], navigationExtras);
      }
    }),
    first()
  );
};

const userHasRequiredPermissions = (
  user: UserStateModel,
  requiredPermissions: Permission[]
): boolean => {
  return requiredPermissions.every((permission) =>
    user.permissions?.includes(permission)
  );
};
