<div class="page-container">
  <div class="message-container">
    <h1>Whoa there!</h1>
    <ng-container [ngSwitch]="routeSpecific">
      <ng-container *ngSwitchCase="'accept-invite'">
        <p>
          You are trying to accept a <strong>SecureFILE</strong> handoff, but
          are still logged into a <strong>SecurePRO</strong> account.
        </p>
        <p>
          You can do these 2 easy steps to finish what you are trying to do:
        </p>
        <ol>
          <li>Logout of your <strong>SecurePRO</strong> account</li>
          <li>
            Go back to your email, and press the Confirm Identity button again
            (This will prompt you to sign up or login as your SecureFILE user)
          </li>
        </ol>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <p>
          You're a <strong>{{ userType$ | async }}</strong> user, so you don't
          have access to this page.
        </p>
      </ng-container>
    </ng-container>
    <button mat-raised-button color="primary" (click)="backToDashboard()">
      Back to Dashboard
    </button>
  </div>
</div>
