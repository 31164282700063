import { Injectable, inject } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { multiFactor } from "firebase/auth";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private auth = inject(Auth);

  get multiFactor() {
    const userAuthObject = this.auth.currentUser;

    return userAuthObject ? multiFactor(userAuthObject) : undefined;
  }

  get enrolledFactors() {
    return this.multiFactor?.enrolledFactors;
  }

  get hasMultiFactor() {
    return this.enrolledFactors && this.enrolledFactors?.length > 0;
  }

  get isPasswordUser() {
    return this.auth.currentUser?.providerData.find(
      (provider) => provider.providerId !== "password"
    );
  }
}
