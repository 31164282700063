import {
  ICustomEmailContent,
  IEntityListItem,
  IEntityPath,
  IGroupMap,
  IGroupToBinMap,
  IOwner,
  IPublicOwner,
  IPublicOwnerListItem,
  IPublicOwnerMap,
  IRole,
  ISelfBillBulkSubscription,
} from "src/app/core/interfaces";
import { convertTimestampsToDates } from "src/app/core/utils/helper-functions";
import { IOrganizationStateModel } from "./secure-pro-model.interface";

export class OrganizationStateObject implements IOrganizationStateModel {
  id: string;
  name: string;
  orgPhone?: string | null;
  orgEmail?: string | null;
  groups: IGroupMap;
  entities?: IGroupToBinMap;
  publicOwners?: IPublicOwnerMap;
  roles?: { [roleId: string]: IRole } | undefined;
  logoUrl?: string;
  stripeCustomerId?: string | null = null;
  selfBilled?: boolean | null = null;
  customEmailContent?: ICustomEmailContent | null = null;
  enterpriseFeaturesEnabled?: boolean | null = null;
  bulkPricingCheckoutType?: ISelfBillBulkSubscription | null = null;

  constructor(org: IOrganizationStateModel) {
    this.id = org.id;
    this.name = org.name;
    this.orgPhone = org.orgPhone;
    this.orgEmail = org.orgEmail;
    this.groups = org.groups ? convertTimestampsToDates(org.groups) : {};
    this.entities = convertTimestampsToDates(this.buildEntities(org));
    this.publicOwners = convertTimestampsToDates(org.publicOwners);
    this.roles = org.roles;
    this.logoUrl = org.logoUrl;
    this.stripeCustomerId = org.stripeCustomerId;
    this.selfBilled = org.selfBilled;
    this.customEmailContent = org.customEmailContent;
    this.enterpriseFeaturesEnabled = org.enterpriseFeaturesEnabled;
    this.bulkPricingCheckoutType = org?.bulkPricingCheckoutType;
  }

  get activeGroups() {
    const activeGroups = Object.keys(this.groups).reduce((acc, groupId) => {
      const group = this.groups[groupId];
      if (group.active) {
        acc[groupId] = group;
      }
      return acc;
    }, {} as IGroupMap);

    return activeGroups;
  }

  /**
   * @description Returns the list of entities in the organization.
   * It flattens the entities object into an array and adds the bin and group information to each entity.
   */
  get entitiesList(): IEntityListItem[] {
    const entitiesList = Object.keys(this.entities || {}).reduce(
      (acc, groupId) => {
        const group = this.groups[groupId];
        if (!this.entities) {
          return acc;
        }
        const entityBins = this.entities[groupId];
        const entities = Object.keys(entityBins).reduce((acc, binIndex) => {
          const bin = entityBins[binIndex];
          const entities = Object.keys(bin).map((entityId) => {
            const entity = bin[entityId];
            return {
              ...entity,
              binIndex,
              groupId,
              groupName: group.name,
              id: entityId,
            };
          });
          return [...acc, ...entities];
        }, [] as IEntityListItem[]);
        return [...acc, ...entities];
      },
      [] as IEntityListItem[]
    );

    return entitiesList;
  }

  get publicOwnersList(): IPublicOwnerListItem[] {
    return Object.values(this.publicOwners || {}).map((owner) => {
      const entities = Object.keys(owner.entities).map((entityId) => {
        return this.getEntityFromId(entityId);
      });
      return {
        ...owner,
        entityNames: Array.from(
          new Set(entities.map((e) => e?.legalName || ""))
        ).sort(),
        groupNames: Array.from(
          new Set(entities.map((e) => e?.groupName || ""))
        ).sort(),
      };
    });
  }

  private buildEntities(org: IOrganizationStateModel): IGroupToBinMap {
    const entities = {} as IGroupToBinMap;

    Object.keys(org.entities || {}).map(async (groupId) => {
      if (!org.entities) {
        return;
      }
      const entityBins = org.entities[groupId];
      entities[groupId] = {};
      await Promise.all(
        Object.keys(entityBins).map(async (binIndex) => {
          const bin = entityBins[binIndex];
          entities[groupId][binIndex] = {};
          await Promise.all(
            Object.keys(bin).map(async (entityId) => {
              const entity = bin[entityId];
              if (!entity) return;
              entities[groupId][binIndex][entityId] = {
                ...entity,
                dueDate: entity.dueDate,
                formationDate: entity.formationDate,
                lastAcceptedFiling: entity.lastAcceptedFiling,
                status: entity.status || "Set Up",
              };
            })
          );
        })
      );
    });
    return entities;
  }

  getEntity(entityId: string): IEntityListItem | undefined {
    const entity = this.entitiesList.find((entity) => entity.id === entityId);
    return entity;
  }
  getEntityPath(entityId: string): IEntityPath | undefined {
    const entity = this.getEntity(entityId);
    if (!entity) {
      return undefined;
    }
    const groupId = entity.groupId;
    const binIndex = entity.binIndex;
    return {
      id: entityId,
      groupId,
      binIndex,
    };
  }

  getEntityGroupId(entityId: string): string | undefined {
    const groupId = Object.keys(this.activeGroups).find((gid) => {
      const group = this.activeGroups[gid];
      if (!group) {
        return false;
      }
      return group.entities.map((entity) => entity.id).includes(entityId);
    });

    return groupId;
  }

  getEntityBinIndex(entityId: string): string | null {
    return (
      this.entitiesList.find((entity) => {
        return entity.id === entityId;
      })?.binIndex || null
    );
  }

  getEntityFromId(entityId: string): IEntityListItem | undefined {
    const entityList = this.entitiesList;
    const entity = entityList.find((entity) => entity.id === entityId);
    return entity;
  }

  getPublicOwnerFromId(ownerId: string): IPublicOwner | undefined {
    const owner = Object.values(this.publicOwnersList)?.find(
      (owner) => owner.id === ownerId
    );
    return owner;
  }

  getUserGroups(uid: string): string[] {
    const userGroups = Object.keys(this.activeGroups).reduce((acc, groupId) => {
      const group = this.activeGroups[groupId];
      if (group.userIds.includes(uid)) {
        acc.push(groupId);
      }
      return acc;
    }, [] as string[]);

    return userGroups;
  }

  getOwners(): IOwner[] {
    const owners = this.entitiesList.flatMap((entity) =>
      entity?.owners ? Object.values(entity.owners) : []
    );
    return owners;
  }

  getOwner(ownerId: string | null): IOwner | undefined {
    if (!ownerId) {
      return undefined;
    }
    const owners = this.getOwners();
    if (!owners) {
      return undefined;
    }
    const owner = owners.find((owner) => owner.id === ownerId);
    return owner;
  }
}
