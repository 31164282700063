import { IEntityDetails } from "src/app/core/interfaces/entity.interface";

export interface EntityDetailsStateModel extends IEntityDetails {
  id: string | null;
}

export const generateBlankEntityDetails = (): EntityDetailsStateModel => {
  return {
    id: null,
    auditLog: [],
    filings: [],
    userIds: [],
    secureFileTransferComplete: false,
    orgId: "",
    jwtToken: "",
    ownerIds: [],
  };
};
