import { inject } from "@angular/core";
import { CanActivateChildFn, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { filter, first, map } from "rxjs";
import { UserStateModel } from "src/app/state/user-state/user-model.interface";

export const userTypeGuard: CanActivateChildFn = (route) => {
  const store = inject(Store);
  const router = inject(Router);

  const userState$ = store.select<UserStateModel>((state) => state.user);

  const parentRoutes = route.pathFromRoot
    .map((path) => path?.url[0]?.path)
    .filter((path) => path);
  const childRoute = route.routeConfig?.path;

  return userState$.pipe(
    filter((userState) => !!userState.id),
    map((userState) => {
      if (
        parentRoutes.includes("secure-file") &&
        userState?.userType === "secure-file"
      ) {
        return true;
      } else if (
        parentRoutes.includes("secure-pro") &&
        userState?.userType === "secure-pro"
      ) {
        return true;
      } else {
        if (childRoute === "invite") {
          return router.createUrlTree(["wrong-user-type"], {
            queryParams: { routeSpecific: "accept-invite" },
          });
        } else {
          return router.createUrlTree(["wrong-user-type"]);
        }
      }
    }),
    first()
  );
};
