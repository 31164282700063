import { IAppEnv } from "src/app/core/interfaces";

export const dontImportMe: IAppEnv = {
  firebase: {
    apiKey: "AIzaSyCAqNoXLcWhBRB5OyLxZvL1tPTHX6EAcfI",
    authDomain: "compliance-6bb12.firebaseapp.com",
    projectId: "compliance-6bb12",
    storageBucket: "compliance-6bb12.appspot.com",
    messagingSenderId: "188979828907",
    appId: "1:188979828907:web:6806edc1d2aa6c1bf93fee",
    measurementId: "G-BGPR9H8T1R",
  },
  stripeKey:
    "pk_live_51OAMSvErs4o4r2jg76LD3TLAYKSDFSSfuhDmvJSw83cTOlMtvYpTG5y57QFoAmg95F1sGfhADIILlhk14spnOPJQ00nRMvjhmJ",
  envType: "production",
};
