import { IEntity, IEntitySubmitConsent } from "src/app/core/interfaces";

export namespace SecureFile {
  export class Reset {
    static readonly type = "[SecureFile] Reset";
  }

  export class GetEntities {
    static readonly type = "[SecureFile] Get Entities";
  }

  export class UpsertEntity {
    static readonly type = "[SecureFile] Upsert Entity";
    constructor(public entityId: string, public entity: IEntity) {}
  }

  export class SetEntity {
    static readonly type = "[SecureFile] Set Entity";
    constructor(public entityId: string, public entity: IEntity) {}
  }

  export class DeleteEntity {
    static readonly type = "[SecureFile] Delete Entity";
    constructor(public entityId: string) {}
  }

  export class DeleteEntitySuccess {
    static readonly type = "[SecureFile] Delete Entity Success";
  }

  export class SignAndSubmit {
    static readonly type = "[SecureFile] Sign And Submit";
    constructor(
      public entityId: string,
      public signerInfo: IEntitySubmitConsent
    ) {}
  }
}
