import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { map } from "rxjs";
import { UserStateModel } from "src/app/state/user-state/user-model.interface";

@Component({
  selector: "app-wrong-user-type",
  templateUrl: "./wrong-user-type.component.html",
  styleUrls: ["./wrong-user-type.component.scss"],
})
export class WrongUserTypeComponent implements OnInit {
  store = inject(Store);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);

  userType$ = this.store
    .select<UserStateModel>((state) => state.user)
    .pipe(
      map((user) => {
        return user?.userType === "secure-file" ? "SecureFILE" : "SecurePRO";
      })
    );

  routeSpecific: string | null = null;

  ngOnInit(): void {
    this.routeSpecific =
      this.activatedRoute.snapshot.queryParams["routeSpecific"];
  }

  backToDashboard() {
    this.router.navigate(["dashboard"]);
  }
}
