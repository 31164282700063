import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CamelToTitlePipe } from "./camel-to-title.pipe";
import { SortPipe } from "./sort.pipe";

@NgModule({
  declarations: [CamelToTitlePipe, SortPipe],
  exports: [CamelToTitlePipe, SortPipe],
  imports: [CommonModule],
})
export class SharedPipesModule {}
