import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FirebaseError } from "firebase/app";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService {
  commonErrorDuration = 5000;

  constructor(private snackBar: MatSnackBar) {}

  handleError(error: unknown) {
    console.error(error);
    Sentry.captureException(error);
    if (!(error instanceof FirebaseError)) {
      if (typeof error === "string") {
        this.snackBar.open(error, "Close", {
          duration: this.commonErrorDuration,
        });
      } else {
        this.snackBar.open("An unknown error occurred", "Close", {
          duration: this.commonErrorDuration,
        });
      }
      return;
    }

    let errorMessage: string;

    // Handle Firebase Errors
    switch (error.code) {
      case "permission-denied":
        errorMessage = "Permission denied";
        break;
      case "auth/email-already-in-use":
        errorMessage = "Email already in use";
        break;
      case "auth/invalid-email":
        errorMessage = "Invalid email";
        break;
      case "auth/operation-not-allowed":
        errorMessage = "Operation not allowed";
        break;
      case "auth/weak-password":
        errorMessage = error.message;
        break;
      case "auth/account-exists-with-different-credential":
        errorMessage = "Account exists with different social provider";
        break;
      case "auth/wrong-password":
        errorMessage = "Invalid login credentials";
        break;
      case "auth/missing-password":
        errorMessage = "Please enter a password";
        break;
      case "storage/object-not-found":
        errorMessage = "Error uploading image attachment. Please contact support.";
        break;
      default:
        errorMessage = "An unknown error occurred";
        break;
    }
    this.snackBar.open(errorMessage, "Close", {
      duration: this.commonErrorDuration,
    });
  }
}
