import { Injectable, inject } from "@angular/core";
import { Functions } from "@angular/fire/functions";
import { httpsCallable } from "firebase/functions";
import {
  EntityStatuses,
  FinCENStatus,
  IEntity,
  IEntityQualifiers,
  IEntitySubmitConsent,
  SubmissionStatuses,
} from "../interfaces";
import axios from "axios";
import { getDownloadURL, getMetadata, ref } from "firebase/storage";
import { Storage } from "@angular/fire/storage";
import { Buffer } from "buffer";
import { ErrorHandlerService } from "./firebase-error-handler.service";
import { CountryToCodeService } from "./country-to-code.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ActionDialogComponent,
  ActionDialogData,
} from "../components/action-dialog/action-dialog.component";
import { Store } from "@ngxs/store";
import { UserStateModel } from "src/app/state/user-state/user-model.interface";
import { SecureFileStateModel } from "src/app/state/secure-file-state/secure-file-model.interface";
import { SecureProStateModel } from "src/app/state/secure-pro-state/secure-pro-model.interface";
import { SecureFile } from "src/app/state/secure-file-state/secure-file.actions";
import { Entity } from "src/app/state/secure-pro-state/secure-pro.actions";
import { CommonApisService } from "./common-apis.service";
import { doc, setDoc } from "firebase/firestore";
import { Firestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FunctionRepeater } from "src/app/core/utils/function-repeater";
import { emailRegex } from "../utils/constants";

interface AccessToken {
  token: string;
  issuedOn: Date | null; // lasts for 1 hour
}

export interface BoirFilingResponse {
  processId: string;
  xmlUploadResponse: string;
  attachmentUploadResponses: { response: string; fileName: string }[];
}

export interface BoirStatusResponse {
  processId: string;
  submissionStatus: SubmissionStatuses;
  errors?: {
    ErrorText?: string;
    ErrorCode?: string;
  }[];
  validationErrors?: {
    errorMessage?: string;
  }[];
  fincenID?: string;
  BOIRID?: string;
}

export interface IEntityUpdates {
  status: EntityStatuses;
  fincenStatus: FinCENStatus | null;
  fincenId?: string;
}

interface IUploadAttachment {
  processId: string;
  fileName: string;
  fileData: string;
  accessToken: string;
  contentType: string;
}

@Injectable({
  providedIn: "root",
})
export class BoirFilingsService {
  private functions: Functions = inject(Functions);
  private accessToken: AccessToken = {
    token: "",
    issuedOn: null,
  };
  private storage = inject(Storage);
  private errorHandler = inject(ErrorHandlerService);
  private countryToCodeService = inject(CountryToCodeService);
  private dialog: MatDialog = inject(MatDialog);
  private store = inject(Store);
  private commonApiService = inject(CommonApisService);
  private firestore = inject(Firestore);
  private snackbar = inject(MatSnackBar);

  async fileBOIR(updatedEntity: IEntity, consentData: IEntitySubmitConsent) {
    try {
      const res: BoirFilingResponse = {
        processId: "",
        xmlUploadResponse: "",
        attachmentUploadResponses: [],
      };

      const processId = await this.initiateBOIR();
      res.processId = processId;

      const qualifiers = this.constructQualifiers(
        consentData,
        updatedEntity.optOutOfFincenId
      );

      const entity =
        this.countryToCodeService.entityToCountryCode(updatedEntity);

      for (const owner of Object.values(entity.owners)) {
        if (updatedEntity.reportType === "Exemption Certificate") break;

        const { docUrl, docIdNum, fincenId } = owner;
        if (!docUrl || !docIdNum || fincenId) continue;

        const uploadAttachment = await this.uploadAttachment(
          processId,
          docIdNum,
          docUrl
        );

        res.attachmentUploadResponses.push(uploadAttachment);
        if (owner && uploadAttachment.fileName) {
          owner.originalAttachmentFileName = uploadAttachment.fileName;
        }
      }

      console.log("\n\n\n\n");
      console.log(entity);
      console.log(consentData);
      console.log("\n\n\n\n");

      const boirXml = await this.generateBoirXML(entity, qualifiers);
      console.log(boirXml);

      const boirXmlResponse = await this.uploadBoirXml(
        processId,
        "test.xml",
        boirXml
      );
      res.xmlUploadResponse = boirXmlResponse;
      console.log(res);

      return res;
    } catch (error) {
      this.errorHandler.handleError(error);
      return Promise.reject(error);
    }
  }

  async writeBOIRTrackingNumber(
    entityId: string,
    response: BoirFilingResponse | undefined
  ) {
    try {
      if (!response) return;

      const boirIdsDoc = doc(this.firestore, "boirFilingResponses", entityId);

      if (!boirIdsDoc) return;

      await setDoc(
        boirIdsDoc,
        {
          [response.processId]: response,
        },
        { merge: true }
      );
    } catch (error) {
      console.error(error);
    }
  }

  private async initiateBOIR() {
    const accessToken = await this.checkAccessToken();

    const initiateBOIRCallable = httpsCallable<{ accessToken: string }, string>(
      this.functions,
      "initiateBOIR"
    );

    const { data } = await initiateBOIRCallable({
      accessToken: accessToken.token,
    });

    return data;
  }

  private async uploadAttachment(
    processId: string,
    fileName: string,
    fileDataUrl: string
  ) {
    try {
      const uploadAttachmentData = await this.prepareUploadAttachmentData(
        processId,
        fileName,
        fileDataUrl
      );

      if (uploadAttachmentData === null) {
        this.errorHandler.handleError(
          "Error during the request, the attached file exceeding the size limit"
        );

        this.snackbar.open(
          "Error during attachment upload. The attached ID file exceeds the size limit"
        );

        return Promise.reject(
          "Error during the request, the attached file exceeding the size limit"
        );
      }

      const uploadAttachmentCallable = httpsCallable<IUploadAttachment, string>(
        this.functions,
        "uploadAttachment"
      );

      const repeater = new FunctionRepeater("Upload Attachment", 3, 500);

      const { success, data: callableResponse } = await repeater.repeatAsync(
        uploadAttachmentCallable,
        uploadAttachmentData.payload
      );

      if (!success) {
        this.snackbar.open(
          "Error during attachment upload. Please, try again later.",
          "Dismiss"
        );

        return Promise.reject(
          "Error during attachment upload. Please, try again later."
        );
      }

      return {
        response: callableResponse.data,
        fileName: uploadAttachmentData.fullFileName,
      };
    } catch (error) {
      this.errorHandler.handleError(
        "Error uploading image attachment. Please contact support."
      );
      return Promise.reject(error);
    }
  }

  private async prepareUploadAttachmentData(
    processId: string,
    fileName: string,
    fileDataUrl: string
  ): Promise<{ payload: IUploadAttachment; fullFileName: string } | null> {
    const accessToken = await this.checkAccessToken();
    const fileUrl = await this.getGovIdUrl(fileDataUrl);
    const fileData = await this.getBase64Image(fileUrl);
    const { contentType } = await this.getGovIdMetadata(fileUrl);

    const compressedFileData =
      await this.commonApiService.variableImageCompressor(
        fileData.toString("base64"),
        contentType ?? "image/jpeg",
        1,
        500
      );

    // additional file size check, mostly for PDF files
    const compressedFileSizeBytes = (compressedFileData.image.length * 3) / 4;
    const compressedFileSizeMb = compressedFileSizeBytes / 1024 / 1024;

    console.log(
      `Compressed image or pdf size ${compressedFileSizeMb.toFixed(2)}Mb`
    );

    if (compressedFileSizeMb >= 4) {
      return null;
    }

    const fileExtension = await this.getGovIdFileExtension(
      compressedFileData.mediaType ?? "image/jpeg"
    );

    const randomInt = Math.floor(Math.random() * 100000);

    const fullFileName = `${fileName}-${randomInt}.${fileExtension}`;

    const requestPayload = {
      processId,
      fileName: fullFileName,
      fileData: compressedFileData.image,
      accessToken: accessToken.token,
      contentType: compressedFileData.mediaType ?? "image/jpeg",
    };

    // max size of Cloud Functions request payload is 10Mb
    const requestPayloadSizeMb =
      JSON.stringify(requestPayload).length / (1024 * 1024);

    console.log(
      `Size of the request payload ${requestPayloadSizeMb.toFixed(2)}Mb`
    );

    if (requestPayloadSizeMb >= 10) {
      return null;
    }

    return {
      payload: requestPayload,
      fullFileName,
    };
  }

  async uploadBoirXml(processId: string, xmlFileName: string, xmlData: string) {
    const accessToken = await this.checkAccessToken();

    const uploadBoirXmlCallable = httpsCallable<
      {
        processId: string;
        xmlFileName: string;
        xmlData: string;
        accessToken: string;
      },
      string
    >(this.functions, "uploadBoirXml");

    const { data } = await uploadBoirXmlCallable({
      processId,
      xmlFileName,
      xmlData,
      accessToken: accessToken.token,
    });

    return data;
  }

  async checkSubmissionStatus(processId: string) {
    const accessToken = await this.checkAccessToken();

    const checkSubmissionStatusCallable = httpsCallable<
      { processId: string; accessToken: string },
      BoirStatusResponse
    >(this.functions, "checkSubmissionStatus");

    const { data } = await checkSubmissionStatusCallable({
      processId,
      accessToken: accessToken.token,
    });

    return data;
  }

  async getTranscript(processId: string, entityId: string) {
    const accessToken = await this.checkAccessToken();

    const getTranscriptCallable = httpsCallable<
      { entityId: string; processId: string; accessToken: string },
      { pdfBinary: string; status: { submissionStatus: string } }
    >(this.functions, "getTranscript");

    const { data } = await getTranscriptCallable({
      entityId,
      processId,
      accessToken: accessToken.token,
    });

    return data;
  }

  async generateBoirXML(entity: IEntity, qualifiers: IEntityQualifiers) {
    const generateBoirXMLCallable = httpsCallable<
      {
        entity: IEntity;
        qualifiers: IEntityQualifiers;
      },
      string
    >(this.functions, "generateBoirXML");

    const { data } = await generateBoirXMLCallable({
      entity,
      qualifiers,
    });

    return data;
  }

  private async checkAccessToken() {
    if (
      this.accessToken.token === "" ||
      this.accessToken.issuedOn === null ||
      this.accessToken.issuedOn.getTime() < Date.now() - 3600000
    ) {
      return this.obtainAccessToken();
    } else {
      console.log(this.accessToken);
      return this.accessToken;
    }
  }

  private async obtainAccessToken() {
    const obtainAccessTokenCallable = httpsCallable<null, string>(
      this.functions,
      "obtainAccessToken"
    );

    const { data } = await obtainAccessTokenCallable();

    this.accessToken = {
      token: data,
      issuedOn: new Date(),
    };

    console.log(this.accessToken);

    return this.accessToken;
  }
  private async getBase64Image(url: string) {
    return axios
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((response) => Buffer.from(response.data, "binary"));
  }

  private async getGovIdUrl(docUrl: string | null) {
    const storageRef = ref(this.storage, docUrl || "");
    return await getDownloadURL(storageRef);
  }

  private async getGovIdMetadata(docUrl: string) {
    const storageRef = ref(this.storage, docUrl || "");
    return await getMetadata(storageRef);
  }

  private async getGovIdFileExtension(contentType: string): Promise<string> {
    return contentType?.split("/")[1] ?? ".jpg";
  }

  private constructQualifiers(
    consentData: IEntitySubmitConsent,
    optOutOfFincenId: boolean
  ): IEntityQualifiers {
    return {
      email: consentData.email,
      firstName: consentData.firstName,
      lastName: consentData.lastName,
      requestFincenId: !optOutOfFincenId,
    };
  }

  public entityIsValid(entity: IEntity, consentData: IEntitySubmitConsent) {
    const dialogData: ActionDialogData = {
      title: "Validation Error",
      message: "",
      positiveAction: false,
      positiveActionButtonText: "Return",
      negativeActionButtonText: "Cancel",
    };

    if (!this.signatoryIsValid(consentData)) {
      this.dialog.open(ActionDialogComponent, {
        width: "350px",
        data: {
          ...dialogData,
          message:
            'Signatory information must include valid email, first name, & last name. If your owner is an entity, please select "Entity is signatory" and try again.',
        },
      });

      return false;
    }

    if (!this.beneficialOwnersIsValid(entity)) {
      this.dialog.open(ActionDialogComponent, {
        width: "350px",
        data: {
          ...dialogData,
          message:
            'If the filing type is not "Newly exempt", at least one Beneficial Owner must be recorded',
        },
      });

      return false;
    }

    if (!this.companyApplicantsIsValid(entity)) {
      this.dialog.open(ActionDialogComponent, {
        width: "350px",
        data: {
          ...dialogData,
          message:
            'If the filing type is not "Newly exempt", and neither "Foreign pooled investment vehicle" nor "Existing Reporting Company" are set, Company Applicant information is required and must be recorded.',
        },
      });

      return false;
    }

    return true;
  }

  private signatoryIsValid(consentData: IEntitySubmitConsent) {
    return (
      emailRegex.test(consentData.email) &&
      consentData.firstName &&
      consentData.lastName
    );
  }

  private beneficialOwnersIsValid(entity: IEntity) {
    return (
      (entity.reportType !== "Exemption Certificate" &&
        Object.values(entity.owners).filter(
          (owner) => !owner.isCompanyApplicant
        ).length > 0) ||
      entity.reportType === "Exemption Certificate"
    );
  }

  private companyApplicantsIsValid(entity: IEntity) {
    const isExistingReportingCompany =
      entity.formationDate &&
      new Date(entity.formationDate) < new Date("2024-01-01");
    const hasCompanyApplicants =
      Object.values(entity.owners).filter((owner) => owner.isCompanyApplicant)
        .length > 0;
    const needsCompanyApplicants =
      entity.reportType !== "Exemption Certificate" &&
      !entity.foreignPooledInvestmentVehicle &&
      !isExistingReportingCompany;
    return needsCompanyApplicants ? hasCompanyApplicants : true;
  }

  async getSecureProEntity(entityId: string) {
    const org = this.store.selectSnapshot(
      (state) => (state.securePro as SecureProStateModel)?.org
    );
    return org.getEntityFromId(entityId) as IEntity;
  }

  async updateEntity(entityId: string, entityUpdates: IEntityUpdates) {
    const secureFileEntity = this.store.selectSnapshot((state) =>
      (state.secureFile as SecureFileStateModel).entities.find(
        (entity) => entity.id === entityId
      )
    );
    const secureProEntity = await this.getSecureProEntity(entityId);
    const entity = {
      ...(secureProEntity as IEntity),
      ...entityUpdates,
    };
    const fileEntity = {
      ...(secureFileEntity as IEntity),
      ...entityUpdates,
    };
    this.updateEntityByType(entityId, entity, fileEntity);
  }

  private async updateEntityByType(
    entityId: string,
    entity?: IEntity,
    secureFileEntity?: IEntity
  ) {
    const userType = this.store.selectSnapshot(
      (state) => (state.user as UserStateModel).userType
    );
    switch (userType) {
      case "secure-file":
        if (!secureFileEntity) return;
        this.store.dispatch(
          new SecureFile.UpsertEntity(entityId, secureFileEntity)
        );
        break;
      case "secure-pro":
        if (!entity) return;
        this.store.dispatch(
          new Entity.Upsert(
            entity?.groupId as string,
            entityId,
            entity,
            {},
            false
          )
        );
        break;
    }
  }

  apiStatusToFinCENStatus(apiStatus: SubmissionStatuses): FinCENStatus | null {
    switch (apiStatus) {
      case "submission_validation_failed":
      case "submission_validation_passed":
      case "submission_processing":
        return null;
      default:
        return apiStatus;
    }
  }

  apiStatusToSubmissionStatus(apiStatus: SubmissionStatuses): EntityStatuses {
    switch (apiStatus) {
      case "submission_accepted":
        return "e-Filing Accepted";
      case "submission_pending":
        return "e-Filing Pending";
      case "submission_rejected":
        return "e-Filing Rejected";
      default:
        return "e-Filing Pending";
    }
  }
}
