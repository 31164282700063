<div class="drag-area">
  <div class="expanded-container" cdkDrag cdkDragLockAxis="x">
    <div class="minimize-button">
      <button
        mat-icon-button
        (click)="minimizeQuickStart()"
        (keypress)="minimizeQuickStart()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="expanded-container-header">
      <div
        echarts
        [options]="chartOption"
        (chartInit)="onChartInit($event)"
        class="big-qs-pie"
      ></div>
      <div class="expanded-info">
        <div class="quick-start-text">Quick Start</div>
        <div class="hint-text">
          {{ numberOfTasksRemaining }} Remaining Tasks
        </div>
      </div>
    </div>
    <!-- Next steps -->
    <ng-container *ngIf="quickStartState$ | async as quickStartState">
      <div class="steps-container">
        <div class="steps-header">Next Steps</div>
        <div class="steps-list">
          <mat-accordion multi>
            <ng-container *ngFor="let step of quickStartState">
              <mat-expansion-panel
                *ngIf="!step.finished && quickStartSteps[step.key]"
                expanded
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ quickStartSteps[step.key].title }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>{{ quickStartSteps[step.key].description }}</p>
                <a
                  [href]="quickStartSteps[step.key].knowledgeBaseLink"
                  target="_blank"
                  >Learn More</a
                >
                <div class="step-actions">
                  <button
                    mat-raised-button
                    (click)="markAsDone(step.key)"
                    [disabled]="checkCompletedState(step.key)"
                    *ngIf="!step.finished"
                  >
                    Done <mat-icon>check</mat-icon>
                  </button>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="startStep(step.key)"
                  >
                    Start
                  </button>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </div>
      </div>
      <!-- Finished steps -->
      <div class="steps-container">
        <div class="steps-header">Finished Steps</div>
        <div class="steps-list">
          <mat-accordion>
            <ng-container *ngFor="let step of quickStartState">
              <mat-expansion-panel *ngIf="step.finished">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ quickStartSteps[step.key].title }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p>{{ quickStartSteps[step.key].description }}</p>
                <a
                  [href]="quickStartSteps[step.key].knowledgeBaseLink"
                  target="_blank"
                  >Learn More</a
                >
                <div class="step-actions">
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="startStep(step.key)"
                  >
                    Try Again
                  </button>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </div>
      </div>
    </ng-container>
  </div>
</div>
