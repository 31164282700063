import { BreakpointObserver } from "@angular/cdk/layout";
import { ElementRef, Injectable } from "@angular/core";

import { Observable, ReplaySubject, Subject, timer } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { IScreenResolution, ScreenSize } from "../interfaces";

/**
 * Service that provides utility functions for working with screen and element breakpoints.
 */
@Injectable({
  providedIn: "root",
})
export class BreakpointsService {
  public breakpoints: IScreenResolution = {
    mobile: { minWidth: 0, maxWidth: 479 },
    tablet: { minWidth: 480, maxWidth: 768 },
    desktop: { minWidth: 769, maxWidth: Infinity },
  };

  constructor(private breakpointObserver: BreakpointObserver) {}

  /**
   * Creates an observable that emits the current breakpoint size of the element initially and on resize.
   *
   * @param {ElementRef} el A reference to the element that you want to observe. Make sure it's called from ngAfterViewInit.
   * @param {Observable<boolean> | ReplaySubject<boolean>} destroy$ The isDestroyed$ observable that's available to all components that extend BaseComponent.
   * @returns {Observable<ScreenSize>} Observable that emits the current breakpoint size.
   */

  createBreakpointObservable(
    el: ElementRef,
    destroy$: Observable<boolean> | ReplaySubject<boolean>
  ): Observable<ScreenSize> {
    // Initialize based on window width
    const breakpoint$ = new Subject<ScreenSize>();

    // The update callback responsible for the size calculation
    const update = () => {
      const width = el.nativeElement.parentElement.offsetWidth;
      let size: ScreenSize;
      if (width <= this.breakpoints.mobile.maxWidth) {
        size = "mobile";
      } else if (
        width >= this.breakpoints.tablet.minWidth &&
        width <= this.breakpoints.tablet.maxWidth
      ) {
        size = "tablet";
      } else {
        size = "desktop";
      }
      breakpoint$.next(size);
    };

    // Initial emit
    timer(0).subscribe(() => update());

    // On window resize
    window.addEventListener("resize", update);

    // Cleanup
    destroy$.subscribe(() => {
      window.removeEventListener("resize", update);
      breakpoint$.complete();
    });

    return breakpoint$.asObservable().pipe(takeUntil(destroy$));
  }

  /**
   * Returns an Observable that indicates whether the viewport width is within the mobile breakpoint.
   *
   * @returns {Observable<boolean>} Observable that emits true if the viewport width is within the mobile breakpoint, otherwise false.
   */
  public isMobileGlobal$(): Observable<boolean> {
    return this.breakpointObserver
      .observe([`(max-width: ${this.breakpoints.mobile.maxWidth}px)`])
      .pipe(map((res) => res.matches));
  }

  /**
   * Returns an Observable that indicates whether the viewport width is within the handset breakpoint.
   *
   * @returns {Observable<boolean>} Observable that emits true if the viewport width is within the handset breakpoint, otherwise false.
   */
  public isHandsetGlobal$(): Observable<boolean> {
    return this.breakpointObserver
      .observe([`(max-width: ${this.breakpoints.tablet.maxWidth}px)`])
      .pipe(map((res) => res.matches));
  }

  /**
   * Returns an Observable that indicates whether the viewport width is within the tablet breakpoint.
   *
   * @returns {Observable<boolean>} Observable that emits true if the viewport width is within the tablet breakpoint, otherwise false.
   */
  public isTabletGlobal$(): Observable<boolean> {
    return this.breakpointObserver
      .observe([
        `(min-width: ${this.breakpoints.tablet.minWidth}px) and (max-width: ${this.breakpoints.tablet.maxWidth}px)`,
      ])
      .pipe(map((res) => res.matches));
  }
}
