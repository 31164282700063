import { Component, OnInit, inject } from "@angular/core";
import { Store } from "@ngxs/store";
import { UserStateModel } from "src/app/state/user-state/user-model.interface";
import { BaseComponent } from "../base/base.component";
import { startWith, takeUntil, tap } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard-redirector",
  templateUrl: "./dashboard-redirector.component.html",
  styleUrls: ["./dashboard-redirector.component.scss"],
})
export class DashboardRedirectorComponent
  extends BaseComponent
  implements OnInit
{
  private store: Store = inject(Store);
  private router: Router = inject(Router);

  user$ = this.store.select<UserStateModel>((state) => state.user);

  ngOnInit(): void {
    const user = this.store.selectSnapshot<UserStateModel>(
      (state) => state.user
    );
    this.user$
      .pipe(
        takeUntil(this.isDestroyed$),
        startWith(user),
        tap((user) => {
          if (user.userType === "secure-file") {
            this.router.navigate(["/secure-file", "dashboard"], {
              queryParamsHandling: "preserve",
            });
          } else if (user.userType === "secure-pro") {
            this.router.navigate(["/secure-pro", "dashboard"], {
              queryParamsHandling: "preserve",
            });
          }
        })
      )
      .subscribe();
  }
}
