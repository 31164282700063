import { Firestore, doc, writeBatch } from "@angular/fire/firestore";
import { IGroupMap } from "../interfaces";

export const updateGroupUsers = async (
  orgId: string,
  updatedGroups: IGroupMap,
  firestore: Firestore
) => {
  const batch = writeBatch(firestore);
  // Update the org with the updated groups
  Object.keys(updatedGroups).forEach((groupId) => {
    const groupDoc = doc(firestore, "organization", orgId, "groups", groupId);
    batch.set(
      groupDoc,
      {
        userIds: updatedGroups[groupId].userIds,
      },
      { merge: true }
    );
  });

  // Commit the batch
  await batch.commit();
};
