import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BoiFilingsDialogComponent } from "./boi-filings-dialog/boi-filings-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { StatusDialogComponent } from './status-dialog/status-dialog.component';

@NgModule({
  declarations: [BoiFilingsDialogComponent, StatusDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatTableModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
  ],
})
export class BoiFilingsModule {}
