import { ISmartAppEnv } from "src/app/core/interfaces";
import { dontImportMe } from "./environment";

const dumbEnv = dontImportMe;

// Add all of the smart env callbacks and export to the app
export const environment: ISmartAppEnv = {
  ...dumbEnv,
  isProd: () => {
    return dontImportMe.envType === "production";
  },
  isLocal: () => {
    return dontImportMe.envType === "local";
  },
  isStaging: () => {
    return dontImportMe.envType === "staging";
  },
  isStagingPreview: () => {
    return dontImportMe.envType === "stagingPreview";
  },
  isDev: () => {
    return dontImportMe.envType === "development";
  },
};
