import { NgModule } from "@angular/core";
import { redirectUnauthorizedTo } from "@angular/fire/auth-guard";
import { RouterModule, Routes } from "@angular/router";
import { DashboardRedirectorComponent } from "./core/components/dashboard-redirector/dashboard-redirector.component";
import { UnauthorizedComponent } from "./core/components/unauthorized/unauthorized.component";
import { authGuard } from "./core/guards/auth.guard";
import { Permission } from "./core/interfaces";
import { userTypeGuard } from "./core/guards/user-type.guard";
import { WrongUserTypeComponent } from "./core/components/wrong-user-type/wrong-user-type.component";
import { BoiFilingsModule } from "./modules/boi-filings/boi-filings.module";

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(["auth/login"]);

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
    data: { title: "Authentication" },
  },
  {
    path: "payments",
    loadChildren: () =>
      import("./modules/payments/payments.module").then(
        (m) => m.PaymentsModule
      ),
    canActivate: [authGuard],
    data: { title: "Payments" },
  },
  {
    path: "dashboard",
    component: DashboardRedirectorComponent,
    canActivate: [authGuard],
  },
  {
    path: "auto-collect",
    loadChildren: () =>
      import("./modules/auto-collect/auto-collect.module").then(
        (m) => m.AutoCollectModule
      ),
    data: { title: "Owner Information" },
  },
  {
    path: "filing",
    loadChildren: () =>
      import("./modules/secure-file/filing/filing.module").then(
        (m) => m.FilingModule
      ),
  },
  {
    path: "entities",
    loadChildren: () =>
      import("./modules/entities/entities.module").then(
        (m) => m.EntitiesModule
      ),
    canActivate: [authGuard],
    data: {
      title: "Entities",
      authGuardPipe: redirectUnauthorizedToLogin,
      animation: "entitiesPage",
    },
  },
  {
    path: "secure-pro",
    canActivateChild: [authGuard, userTypeGuard],
    children: [
      {
        path: "groups",
        loadChildren: () =>
          import("./modules/secure-pro/groups/groups.module").then(
            (m) => m.GroupsModule
          ),
        canActivate: [authGuard],
        data: {
          title: "Groups",
          // authGuardPipe: redirectUnauthorizedToLogin,
          animation: "groupsPage",
          permissions: ["manageGroups"] as Permission[],
        },
      },
      {
        path: "users",
        loadChildren: () =>
          import("./modules/secure-pro/users/users.module").then(
            (m) => m.UsersModule
          ),
        canActivate: [authGuard],
        data: {
          title: "Users",
          animation: "usersPage",
          // authGuardPipe: redirectUnauthorizedToLogin,
          permissions: ["manageUsers", "manageRoles"] as Permission[],
        },
      },
      {
        path: "owners",
        loadChildren: () =>
          import("./modules/secure-pro/owners/owners.module").then(
            (m) => m.OwnersModule
          ),
        canActivate: [authGuard],
        data: {
          title: "Owners",
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: "ownersPage",
        },
      },
      {
        path: "organization",
        loadChildren: () =>
          import("./modules/secure-pro/organization/organization.module").then(
            (m) => m.OrganizationModule
          ),
        canActivate: [authGuard],
        data: {
          title: "Organization",
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: "organizationPage",
        },
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/secure-pro/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        canActivate: [authGuard],
        data: {
          title: "Dashboard",
          authGuardPipe: redirectUnauthorizedToLogin,
          animation: "dashboardPage",
        },
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./modules/secure-pro/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
        canActivate: [authGuard],
        data: {
          title: "Settings",
          authGuardPipe: redirectUnauthorizedToLogin,
          permissions: ["billing"] as Permission[],
        },
      },
      {
        path: "**",
        redirectTo: "dashboard",
      },
    ],
  },
  {
    path: "secure-file",
    canActivateChild: [authGuard, userTypeGuard],
    children: [
      {
        path: "invite",
        loadChildren: () =>
          import(
            "./modules/secure-file/accept-invite/accept-invite.module"
          ).then((m) => m.AcceptInviteModule),
        canActivate: [authGuard],
        data: {
          title: "SecureFILE Invite",
          animation: "invitePage",
        },
      },
      {
        path: "**",
        redirectTo: "/entities",
        data: { animation: "dashboardPage" },
      },
    ],
  },
  {
    path: "unauthorized",
    component: UnauthorizedComponent,
    data: { title: "Unauthorized" },
  },
  {
    path: "wrong-user-type",
    component: WrongUserTypeComponent,
    data: { title: "Wrong User Type" },
  },
  {
    path: "**",
    redirectTo: "auth/login",
    pathMatch: "full",
    data: { title: "Not Found", animation: "notFoundPage" },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), BoiFilingsModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
