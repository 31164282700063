import { IOrganization } from "../../core/interfaces";
import { UserStateModel } from "../user-state/user-model.interface";
import { OrganizationStateObject } from "./organization-model";

export interface IOrganizationStateModel extends IOrganization {
  id: string;
}

export interface SecureProStateModel {
  org: OrganizationStateObject;
  orgUsers: UserStateModel[]; // List of users in the user's org,
}

export const defaultSecureProState: SecureProStateModel = {
  org: new OrganizationStateObject({
    id: "",
    name: "",
    groups: {},
    entities: {},
    roles: {},
  }),
  orgUsers: [],
};
