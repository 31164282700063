/**
 * This file contains all of the NgRx actions.
 */

import { IPublicOwnerMap, IUploadImageRequest } from "src/app/core/interfaces";
import {
  ICustomEmailContent,
  IEntity,
  IEntitySelfBillUpdate,
  IEntitySubmitToFinCENUpdate,
  IGroup,
  IGroupToBinMap,
  IOwner,
  Role,
} from "../../core/interfaces";
import { UserStateModel } from "../user-state/user-model.interface";

/**
 * @description User actions
 */
export namespace OrgUser {
  export class Update {
    static readonly type = "[OrgUser] Update";
    constructor(public updatedUser: UserStateModel) {}
  }

  export class UpdateGroupMembership {
    static readonly type = "[OrgUser] Update Group Membership";
    constructor(public userId: string, public groupIds: string[]) {}
  }

  export class GetAllUsers {
    static readonly type = "[OrgUser] Get All Users";
  }

  export class InviteUser {
    static readonly type = "[OrgUser] Invite User";
    constructor(
      public email: string,
      public role: Role,
      public groupIds: string[]
    ) {}
  }

  export class Delete {
    static readonly type = "[OrgUser] Delete User";
    constructor(public uid: string, public role: Role) {}
  }

  export class Reset {
    static readonly type = "[OrgUser] Reset";
  }
}

/**
 * @description Organization actions
 */
export namespace Organization {
  export class Get {
    static readonly type = "[Organization] Get";
    constructor(public orgId: string) {}
  }

  export class Update {
    static readonly type = "[Organization] Update";
    constructor(public name: string) {}
  }

  export class UpdateLogo {
    static readonly type = "[Organization] UpdateLogo";
    constructor(public logoUrl: string) {}
  }

  export class SetGlobalSelfBill {
    static readonly type = "[Organization] SetGlobalSelfBill";
    constructor(public selfBilled: boolean) {}
  }

  export class Reset {
    static readonly type = "[Organization] Reset";
  }

  export class UpdateName {
    static readonly type = "[Organization] UpdateName";
    constructor(public name: string) {}
  }

  export class UpdateContactInformation {
    static readonly type = "[Organization] UpdateContactInformation";
    constructor(
      public orgPhone: string | null,
      public orgEmail: string | null
    ) {}
  }

  export class UpdateCustomEmailContent {
    static readonly type = "[Organization] UpdateCustomEmailContent";
    constructor(public content: ICustomEmailContent) {}
  }

  export class UpdateEnterpriseFeaturesEnabled {
    static readonly type = "[Organization] UpdateEnterpriseFeaturesEnabled";
    constructor(public enterpriseFeaturesEnabled: boolean) {}
  }
}

/**
 * @description Group actions
 */
export namespace Group {
  export class Upsert {
    static readonly type = "[Group] Upsert Group";
    constructor(public groupId: string, public group: IGroup) {}
  }

  export class Delete {
    static readonly type = "[Group] Delete Group";
    constructor(public groupId: string) {}
  }
}

/**
 * @description Entity actions
 */
export namespace Entity {
  export class GetList {
    static readonly type = "[Entity] Get List";
    constructor(public groupIds: string[]) {}
  }
  export class GetListSuccess {
    static readonly type = "[Entity] Get List Success";
    constructor(public entities: IGroupToBinMap) {}
  }

  export class Upsert {
    static readonly type = "[Entity] Upsert";
    constructor(
      public groupId: string,
      public entityId: string,
      public entity: IEntity,
      public ownerImages: { [ownerId: string]: IUploadImageRequest },
      public ownersUpdated: boolean
    ) {}
  }
  export class UpsertSuccess {
    static readonly type = "[Entity] Upsert Success";
    constructor(public groupChanged: boolean, public ownersUpdated: boolean) {}
  }

  export class TransferToSecureFile {
    static readonly type = "[Entity] Transfer To Secure File";
    constructor(public entityId: string, public ownerId: string) {}
  }

  export class RevokeSecureFileInvite {
    static readonly type = "[Entity] Revoke Secure File Invite";
    constructor(public entityId: string) {}
  }

  export class Delete {
    static readonly type = "[Entity] Delete";
    constructor(public entityId: string) {}
  }

  export class DeleteSuccess {
    static readonly type = "[Entity] Delete Success";
  }

  export class SelfBill {
    static readonly type = "[Entity] Self Bill";
    constructor(public entityUpdates: IEntitySelfBillUpdate[]) {}
  }

  export class SelfBillSuccess {
    static readonly type = "[Entity] Self Bill Success";
  }

  export class SubmitToFinCEN {
    static readonly type = "[Entity] Submit To FinCEN";
    constructor(public entityUpdate: IEntitySubmitToFinCENUpdate) {}
  }

  export class SubmitToFinCENSuccess {
    static readonly type = "[Entity] Submit To FinCEN Success";
  }

  export class UpdateStripeSubscription {
    static readonly type = "[Entity] Update Stripe Subscription";
    constructor(public entityId: string, public subscriptionId: string) {}
  }

  /**
   * @description Given an entity, this action updates the state with the entity.
   */
  export class SetEntity {
    static readonly type = "[Entity] Set Entities";
    constructor(public entityId: string, public entity: IEntity) {}
  }
}

/**
 * @description Owner actions
 */
export namespace Owner {
  export class GetPublicOwners {
    static readonly type = "[Owner] Get Public Owners";
  }

  export class GetPublicOwnersSuccess {
    static readonly type = "[Owner] Get Public Owners Success";
    constructor(public owners: IPublicOwnerMap) {}
  }

  export class Delete {
    static readonly type = "[Owner] Delete Owner";
    constructor(public ownerId: string) {}
  }

  export class DeleteSuccess {
    static readonly type = "[Owner] Delete Owner Success";
  }

  export class RemoveFromEntity {
    static readonly type = "[Owner] Remove From Entity";
    constructor(public entityId: string, public ownerId: string) {}
  }

  export class Update {
    static readonly type = "[Owner] Update Owner";
    constructor(
      public owner: IOwner,
      public ownerImage?: IUploadImageRequest
    ) {}
  }

  export class UpdateSuccess {
    static readonly type = "[Owner] Update Owner Success";
  }
}
